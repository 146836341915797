<div class="modal-header">
    <h4 class="modal-title" id="modal-title">Please log in to continue</h4>
</div>
<div class="alert alert-warning m-0 rounded-0">
    Your session has expired. Please enter your password to continue using the site.
</div>
<div class="modal-body">
    <div *ngIf="errorMessage || retries < 3" class="alert alert-danger">
        <ng-container *ngIf="errorMessage">{{errorMessage}}</ng-container>
        <strong>Remaining attempts: {{retries}}</strong>
    </div>
    <form>
        <div class="form-group">
            <label class="control-label w-100">{{configService.loginUserLabel}}</label>
            <input [(ngModel)]="email" name="email" type="email" class="form-control-plaintext strong" readonly>
        </div>
        <div class="form-group">
            <label class="control-label w-100">Password</label>
            <input [(ngModel)]="password"
                   name="password"
                   type="password"
                   class="form-control"
                   ngbAutoFocus
                   (keyup.enter)="handleLogin()"
                   [disabled]="reAuthRequest.isPending"
                   data-test-id="login-modal-password">
        </div>
    </form>
</div>
<div class="modal-footer d-flex justify-content-between">
    <button type="button" class="btn btn-danger" (click)="handleLogout()" [disabled]="reAuthRequest.isPending">Log Out</button>
    <button type="button" class="btn btn-primary" (click)="handleLogin()" [appUnderProcessing]="reAuthRequest.isPending" [disabled]="!email || !password" data-test-id="login-modal-login-btn">Log In</button>
</div>
