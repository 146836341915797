import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {UserAuthService} from '../../../../@core/user-auth.service';
import {take} from 'rxjs/operators';
import {User} from '../../../../models/user';
import {RequestHandler} from '../../../../@core/utils/request-handler';
import {from} from 'rxjs';
import {UnsubscribeComponent} from '../../../../@core/fc-component';
import {extractErrorMessage} from '../../../../utils/error.utils';
import {ConfigService} from '../../../../@core/config.service';

@Component({
    selector: 'app-login-modal',
    templateUrl: './login-modal.component.html',
    host: {'data-test-id': 'login-modal'},
})
export class LoginModalComponent extends UnsubscribeComponent implements OnInit {
    email: string;
    password: string;
    retries: number;
    errorMessage: string;
    user: User;
    reAuthRequest = new RequestHandler(
        (email, password) => from(this.userAuth.login(email, password, true)),
        {
            showErrorToast: false,
            destroy$: this.destroy$,
        }
    );

    constructor(public configService: ConfigService,
                private activeModal: NgbActiveModal,
                private userAuth: UserAuthService) {
        super();
    }

    ngOnInit(): void {
        if (!UserAuthService.hasUser) {
            this.activeModal.dismiss('No credentials present in previous User Model');
            return;
        }

        this.userAuth.user.pipe(take(1)).subscribe(user => {
            this.email = user.email;
            this.retries = 3;
            this.user = user;
        });
    }

    handleLogin() {
        if (!this.email || !this.password) return;

        this.reAuthRequest.call(this.email, this.password).subscribe(() => {
            this.activeModal.close('Successful login');
        }, err => {
            this._handleLoginError(err);
        });
    }

    handleLogout() {
        this.activeModal.dismiss('logout');
    }

    private _handleLoginError(err) {
        this.errorMessage = err.non_field_errors ? err.non_field_errors.join('. ') : extractErrorMessage(err);
        this.retries -= 1;
        this._handleRetries();
    }

    private _handleRetries() {
        this.password = '';
        if (this.retries > 0) return;

        this.activeModal.dismiss('logout');
    }
}
